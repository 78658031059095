import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import BrowserWindow from './BrowserWindow'

const ThemeCard = ({
  image,
  title,
  subtitle,
  price,
  link,
  alt,
  free,
  height,
  newRelease,
}) => (
  <Wrapper to={link}>
    <BrowserWindow image={image} alt={alt} height={height} />
    <Content>
      <div>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </div>
      <Meta>
        {price && <Price>{price}</Price>}
        {free && <Free>Free</Free>}
        {newRelease && <New>New</New>}
      </Meta>
    </Content>
  </Wrapper>
)

export default ThemeCard

const Wrapper = styled(Link)`
  color: ${props => props.theme.color.text.base};

  &:hover {
    .theme-card__overlay {
      opacity: 1;
    }
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0 0;
`

const Title = styled.h3`
  font-size: 1.8rem;
  margin: 0;
`

const Subtitle = styled.p`
  font-size: 1.6rem;
  margin: 0;
  color: ${props => props.theme.color.text.lightest};
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Price = styled.p`
  font-size: 1.8rem;
  margin: 0;
  white-space: nowrap;
`

const Free = styled.p`
  font-size: 1.3rem;
  margin: 0;
  background-color: ${props => props.theme.color.primary.base};
  color: ${props => props.theme.color.inverted};
  letter-spacing: 0.05em;
  padding: 5px 8px;
  text-transform: uppercase;
  border-radius: 6px;
`

const New = styled.p`
  font-size: 1.3rem;
  margin: 0;
  background-color: ${p => p.theme.color.green};
  color: ${p => p.theme.color.inverted};
  letter-spacing: 0.05em;
  padding: 5px 8px 5px 10px;
  text-transform: uppercase;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 6px;

  &:before {
    content: '';
    width: 3px;
    height: 3px;
    background-color: #fff;
    display: block;
    border-radius: 100%;
    border: 3px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 6px 1px #fff;
  }
`
