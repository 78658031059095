import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../../components/Layout'
// import ThemesNav from '../../../components/ThemesNav'
import PageHeader from '../../../components/PageHeader'
import ThemeCard from '../../../components/ThemeCard'
import MetaData from '../../../components/MetaData'
import {BoxedContainer, Section, ThemeCardsGrid} from '../../../elements'

const GhostThemesPage = ({data}) => (
  <Layout>
    <MetaData data={data.currentPage} />
    {/* <ThemesNav /> */}
    <PageHeader title="Ghost Themes" />
    <Section padding="both">
      <BoxedContainer>
        <ThemeCardsGrid>
          <ThemeCard
            image={data.ampersand}
            title="Ampersand"
            subtitle="Ghost"
            link="/themes/ghost/ampersand/"
            alt="Ampersand Ghost theme"
            price="$ 99"
            height="340px"
            newRelease
          />
          <ThemeCard
            image={data.sapphire}
            title="Sapphire"
            subtitle="Ghost"
            link="/themes/ghost/sapphire/"
            alt="Sapphire Ghost theme"
            price="$ 79"
            height="340px"
          />
          <ThemeCard
            image={data.chronicles}
            title="Chronicles"
            subtitle="Ghost"
            link="/themes/ghost/chronicles/"
            alt="Chronicles ghost theme"
            price="$ 30"
            height="340px"
          />
          <ThemeCard
            image={data.sublime}
            title="Sublime"
            subtitle="Ghost"
            link="/themes/ghost/sublime/"
            alt="Sublime ghost theme"
            price="$ 30"
            height="340px"
          />
          {/* <ThemeCard
            image={data.elementary}
            title="Elementary"
            subtitle="Ghost"
            link="/themes/ghost/elementary/"
            alt="Elementary ghost theme"
            price="$ 30"
            height="340px"
          /> */}
          <ThemeCard
            image={data.shuttle}
            title="Shuttle"
            subtitle="Ghost"
            link="/themes/ghost/shuttle/"
            alt="Shuttle ghost theme"
            price="$ 30"
            height="340px"
          />
          <ThemeCard
            image={data.flamingo}
            title="Flamingo"
            subtitle="Ghost"
            link="/themes/ghost/flamingo/"
            alt="Flamingo ghost theme"
            price="$ 30"
            height="340px"
          />
          <ThemeCard
            image={data.paperback}
            title="Paperback"
            subtitle="Ghost"
            link="/themes/ghost/paperback/"
            alt="Paperback ghost theme"
            price="$ 30"
            //height="290px"
          />
          {/* <ThemeCard
            image={data.pamphlet}
            title="Pamphlet"
            subtitle="Ghost"
            link="/themes/ghost/pamphlet/"
            alt="Pamphlet ghost theme"
            price="$ 30"
            height="340px"
          /> */}
          {/* <ThemeCard
            image={data.lotus}
            title="Lotus"
            subtitle="Ghost"
            link="/themes/ghost/lotus/"
            alt="Lotus ghost theme"
            free
            height="340px"
          /> */}
        </ThemeCardsGrid>
      </BoxedContainer>
    </Section>
  </Layout>
)

export default GhostThemesPage

export const query = graphql`
  query {
    currentPage: ghostPage(slug: {eq: "ghost-themes"}) {
      ...GhostMetaPageFields
    }
    ampersand: file(relativePath: {eq: "themes/ampersand.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    sapphire: file(relativePath: {eq: "themes/sapphire.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    chronicles: file(relativePath: {eq: "themes/chronicles.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    sublime: file(relativePath: {eq: "themes/sublime.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    flamingo: file(relativePath: {eq: "themes/flamingo.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    shuttle: file(relativePath: {eq: "themes/shuttle.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    elementary: file(relativePath: {eq: "themes/elementary.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    paperback: file(relativePath: {eq: "themes/paperback.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    lotus: file(relativePath: {eq: "themes/lotus.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    pamphlet: file(relativePath: {eq: "themes/pamphlet.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
  }
`
